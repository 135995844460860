import React from "react";
import Page from "./../components/Page";
import SEO from "./../components/SEO";
import { StaticImage } from "gatsby-plugin-image";

function ExamplesPage() {
  return (
    <Page>
      <SEO title="Examples | OHIF" />
      <main className="my-16 bg-blue-800">
        <div className="grid grid-cols-12 lg:static">
          {/* INTRO */}
          <div className="xl:col-span-1"></div>
          <div className="phone-size:main-padding-xs md:main-padding-md lg:main-padding-lg-with-padding xl:col-span-11 phone-size:col-span-12">
            <div className="text-white">
              <h1 className="phone-size:h1-heading-ps lg:h1-heading-lg">
                Use Cases
              </h1>
              <div className="pt-10 mr-4">
                <StaticImage
                  alt="Crowds Cure Cancer"
                  src={"../images/example-crowds-cure-3x.jpg"}
                />
              </div>
            </div>
          </div>
          <div className="ccc-box phone-size:col-span-12 lg:col-span-6 xl:col-span-5 lg:z-30 lg:absolute lg:ccc-box-configuration">
            <h1 className="phone-size:ccc-heading-ps lg:ccc-heading-lg">
              Crowds Cure Cancer
            </h1>
            <div className="phone-size:ccc-description-ps lg:ccc-description-lg">
              A crowd-sourcing experiment to accelerate quantitative imaging.
              Participants annotated lesions in data from The Cancer Imaging
              Archive.
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 my-32 lg:static">
          {/* INTRO */}
          <div className="xl:col-span-1"></div>
          <div className="phone-size:main-padding-xs md:main-padding-md lg:main-padding-lg-with-padding xl:col-span-11 phone-size:col-span-12">
            <div className="pt-10 mr-4">
              <StaticImage
                alt="NCI Imaging Data Commons"
                src={"../images/example-imaging-data-commons-3x.jpg"}
              />
            </div>
          </div>
          <div className="idc-box phone-size:col-span-12 lg:col-span-6 xl:col-span-5 lg:z-30 lg:absolute lg:idc-box-configuration">
            <h1 className="phone-size:ccc-heading-ps lg:ccc-heading-lg">
              NCI Imaging Data Commons
            </h1>
            <div className="phone-size:ccc-description-ps lg:ccc-description-lg">
              A node of the Cancer Research Data Commons, which provides secure
              access to a large, comprehensive collection of cancer research
              data.
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 my-32">
          <div className="xl:col-span-1"></div>
          <div className="phone-size:main-padding-xs md:main-padding-md lg:main-padding-lg-with-padding xl:col-span-11 phone-size:col-span-12">
            <div className="text-white">
              <div className="pt-10 mr-4">
                <StaticImage
                  alt="ProstateCancer.ai"
                  src={"../images/example-prostate-cancer-ai@2x.jpg"}
                />
              </div>
            </div>
          </div>
          <div className="prostate-box phone-size:col-span-12 lg:col-span-6 xl:col-span-5 lg:absolute lg:z-30 lg:prostate-box-configuration">
            <h1 className="phone-size:ccc-heading-ps lg:ccc-heading-lg">
              ProstateCancer.ai
            </h1>
            <div className="phone-size:ccc-description-ps lg:ccc-description-lg">
              AI tools for identifying clinically significant prostate cancer
              and training users on Prostate Imaging - Reporting and Data System
              (PIRADS) reporting.
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 my-32">
          <div className="xl:col-span-1"></div>
          <div className="phone-size:main-padding-xs md:main-padding-md lg:main-padding-lg-with-padding xl:col-span-11 phone-size:col-span-12">
            <div className="text-white">
              <div className="pt-10 mr-4">
                <StaticImage
                  alt="eContour"
                  src={"../images/example-econtour@2x.jpg"}
                />
              </div>
            </div>
          </div>
          <div className="eContour-box phone-size:col-span-12 lg:col-span-6 xl:col-span-5 lg:absolute lg:z-30 lg:eContour-box-configuration">
            <h1 className="phone-size:ccc-heading-ps lg:ccc-heading-lg">
              eContour
            </h1>
            <div className="phone-size:ccc-description-ps lg:ccc-description-lg">
              Open-access interactive contouring resource for radiation
              oncologists that aims to facilitate point-of-care decision support
              through evidence-based treatment guidelines
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}

export default ExamplesPage;
